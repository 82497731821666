<template>
	<div >
		<div class="ft20 cl-black cl-main ftw500">积分记录</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item>
		
					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
		
					<a-form-item label="操作日期">
						<a-range-picker @change="changeOperateTime" v-model="search.operate_time" valueFormat="YYYY-MM-DD" />
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>
		<div class="mt20">
			<div class="wxb-table-white">
				<a-table rowKey="member_integral_logs_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					<div class="flex alcenter center" slot="member_id" slot-scope="record">
						{{record.member != null ? record.member.member_id : '-'}}
					</div>
										 
					<div  slot="member" slot-scope="member,record">
						<div v-if="member != null" class="flex alcenter center">
							<img v-if="member.face!=null" :src="member.face" class="member-money-recharge-face" />
							<img v-else src="../../../assets/image/woyaoyou_head_defult@2x.png" class="member-money-recharge-face" />
							<div class="ml5" style="text-align: left;">
								<div>{{member.nick_name}}</div>
								<div class="mt2">{{member.mobile}}</div>
							</div>
						</div>
						<div v-else>-</div>
					</div>
					
					<div class="flex left" slot="mobile" slot-scope="record">
						{{record.member != null ? record.member.mobile : '-'}}
					</div>
					
					<div class="text-right" slot="integral" slot-scope="record">
						{{record.type==1 ? '+' : '-'}} {{record.integral}}
					</div>
					
					<div class="text-center" slot="remarks" slot-scope="remarks,record">
						{{remarks ? remarks : '-'}}
					</div>
					
					<div class="text-center" slot="operator" slot-scope="record">
						<div v-if="record.operate_name && record.operate_mobile">
							<div class="ft14 cl-info">{{record.operate_name}}</div>
							<div class="ft12 cl-notice">({{record.operate_mobile}})</div>
						</div>
						<div v-else>-</div>
					</div>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
  import {listMixin} from '@/common/mixin/list';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					nick_name: '',
					mobile: "",
					operate_time:['',''],
				},
				columns: [
					{title: '会员编号',key: 'member_id',align: 'center',scopedSlots: {customRender: 'member_id'}},
					{title: '会员',dataIndex: 'member',align: 'left',scopedSlots: {customRender: 'member'}},
          {title: '积分',key: 'integral',align: 'right',scopedSlots: {customRender: 'integral'}},
					{title: '类型',dataIndex: 'info',align: 'left',ellipsis: true},
					{title: '时间',dataIndex: 'add_time_format',align: 'left',ellipsis: true},
					{title: '备注信息',dataIndex: 'remarks',align: 'left',scopedSlots: {customRender: 'remarks'}},
					{title: '操作人',key: 'operator',align: 'center',scopedSlots: {customRender: 'operator'}},
				],
				datas: [],
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/member/getMemberIntegralLogsList',{
					nick_name:this.search.nick_name,
					mobile:this.search.mobile,
					operate_time:this.search.operate_time,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			changeOperateTime(){
				this.search.operate_time=value;
			},
			
			searchCancel() {
				this.search = {
					nick_name: '',
					mobile: "",
					operate_time:['',''],
				};
			},
			searchAct(){
				this.getLists();
			},
			cancelAct(){
				this.searchCancel();
				this.searchAct();
			}
		}
	}
</script>

<style>
	.member-integral-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-integral-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
	
	.member-money-recharge-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>
